import './App.css'
import {
  Text,
  VStack,
  HStack,
  Box,
  Grid,
  GridItem,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  useMediaQuery
} from '@chakra-ui/react'
import nft_logo from './assets/nft_logo.webp'
import { FiArrowDownRight, FiCheck } from 'react-icons/fi'
import nft_image from './assets/nft_image.jpeg'
import { useState } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import TokenTransferJson from './contracts/TokenTransfer.json'
import axios from 'axios'
import '@skalenetwork/metaport/dist/style.css'
import ConnectWallet from './componenets/ModalSteps/connectWallet'
import BuyRaasPass from './componenets/ModalSteps/buyRaasPass'
import PioneerLogo from './assets/pioneer_logo.svg'
import SilverLogo from './assets/silver_logo.svg'
import GoldLogo from './assets/gold_logo.svg'
import FreeRaasPass from './componenets/ModalSteps/freeRaasPass'
import NoBalance from './componenets/ModalSteps/noBalance'
import MetamaskIcon from './assets/metamask_icon_min.png'

const NFT_Item = ({ name, abilities }) => {
  function getHeight () {
    if (abilities.length % 2 == 0) {
      return `${(abilities.length / 2) * 25}px`
    } else {
      if (abilities.length > 2) {
        return `${((abilities.length + 1) / 2) * 25}px`
      } else {
        return '25px'
      }
    }
  }

  function getLogo () {
    const logos = {
      Silver: SilverLogo,
      Pioneer: PioneerLogo,
      Gold: GoldLogo
    }

    return logos[name]
  }

  return (
    <VStack
      maxW='350px'
      bg='#0F0F11'
      h='max-content'
      minW='350px'
      borderRadius={'20px'}
      align={'flex-start'}
      color='white'
      role='group'
      cursor={'pointer'}
      boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
    >
      <Image
        bg='black'
        borderWidth='none'
        h='300px'
        w='full'
        borderTopRadius={'20px'}
        src={nft_image}
      />
      <VStack
        w='full'
        align={'flex-start'}
        px='5'
        py='4'
        _groupHover={{
          bg: 'white',
          color: 'black'
          // px: '7'
        }}
        transition={'all 300ms'}
        borderBottomRadius={'20px'}
      >
        <HStack>
          <Image
            h='60px'
            w='60px'
            src={getLogo()}
            _groupHover={{
              transform: 'translateY(-70px)',
              opacity: '1'
            }}
            transition={'all 300ms'}
            position={'absolute'}
            opacity={'0'}
          />
          <Text fontSize={'23px'} fontWeight={'bold'}>
            The {name} Pass
          </Text>
        </HStack>
        <HStack
          h='0'
          overflow={'hidden'}
          transition={'all 300ms'}
          _groupHover={{
            h: getHeight()
          }}
          w='full'
          flexWrap={'wrap'}
        >
          {abilities?.map(abl => (
            <HStack borderRadius={'full'} fontSize={'14px'}>
              <FiCheck />
              <Text>{abl}</Text>
            </HStack>
          ))}
        </HStack>
      </VStack>
    </VStack>
  )
}

function App () {
  const [isOpen, setIsOpen] = useState(false)
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  function goToSection () {
    document.querySelector('#nft_container').scrollIntoView({
      behavior: 'smooth'
    })
  }
  function onClose () {
    setIsOpen(false)
  }

  function handleModal () {
    if (isLargerThan800) {
      setIsOpen(true)
    } else {
      if (typeof window.ethereum === 'undefined') {
        window.open('https://metamask.app.link/dapp/nft.hitmakr.io')
      } else {
        setIsOpen(true)
      }
    }
  }

  return (
    <VStack
      h='max-content'
      w='full'
      bg='hsla(257, 98%, 20%, 1)'
      px={isLargerThan800 ? '20' : '5'}
      py={isLargerThan800 ? '20' : '10'}
      color='white'
      justify={'center'}
      align={'center'}
      bgImage={`radial-gradient(
      at 40% 20%,
      hsla(257, 98%, 20%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(155, 90%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(262, 47%, 23%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 50%, hsla(204, 63%, 58%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(317, 43%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(288, 38%, 42%, 1) 0px, transparent 50%);`}
    >
      <Grid
        gridTemplateColumns={isLargerThan800 ? 'repeat(2, 1fr)' : '1fr'}
        h={isLargerThan800 ? '100vh' : 'max-content'}
        w='full'
      >
        <GridItem h='full' w='full'>
          <VStack
            h='full'
            w='full'
            justify={isLargerThan800 ? 'center' : 'flex-start'}
            align={'flex-start'}
          >
            <Text
              fontSize={'20px'}
              fontWeight={''}
              bg='white'
              color='black'
              borderRadius={'md'}
              px='3'
            >
              Introducing <b>HITMAKR RaaS Pass</b>
            </Text>
            {!isLargerThan800 && <Box h='5' />}
            <Text
              fontSize={isLargerThan800 ? '70px' : '40px'}
              fontWeight={'bold'}
              lineHeight={isLargerThan800 ? '5rem' : '3rem'}
            >
              Bringing You Revolution, As a Service.
            </Text>
            <Text fontSize={'larger'}>
              Unlock the power of the revolution of music with Hitmakr, the
              cutting edge Web3 audio marketplace that connects the gap between
              artists and their audience. Step into the Hitmakr Alpha. Let’s
              build the next era of audio, together.
            </Text>
            <Box h='60px' />
            <HStack
              spacing={'10'}
              px='0'
              py='0'
              w='full'
              justify={'space-between'}
              flexWrap={'wrap'}
            >
              <VStack
                align={'flex-start'}
                spacing={'2'}
                lineHeight={'1.3rem'}
                borderRadius={'md'}
                w='1fr'
              >
                <Text fontSize='xx-large' fontWeight={'bold'}>
                  1500+
                </Text>
                <VStack spacing={'0'} align={'flex-start'}>
                  <Text>Community Members</Text>
                </VStack>
              </VStack>
              <VStack
                w='1fr'
                align={'flex-start'}
                spacing={'2'}
                lineHeight={'1.3rem'}
                borderRadius={'md'}
              >
                <Text fontSize='xx-large' fontWeight={'bold'}>
                  1000+
                </Text>
                <VStack spacing={'0'} align={'flex-start'}>
                  <Text>RaaS Pass Airdrop</Text>
                </VStack>
              </VStack>

              <Button
                h={isLargerThan800 ? 'full' : 'auto'}
                mt='-2'
                w={isLargerThan800 ? '1fr' : '100%'}
                // bg='#513184'
                color='white'
                py={isLargerThan800 ? 'auto' : '6'}
                px='4'
                border={'2px solid transparent'}
                bg='linear-gradient(#220E69, #220E69) padding-box,
                linear-gradient(to right, darkorchid, darkblue ) border-box;'
                boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
                transition={'all 300ms'}
                _hover={{
                  bg: 'linear-gradient(#220E69, #220E6998) padding-box,linear-gradient(to right, darkorchid, darkblue ) border-box;',
                  transform: 'scale(1.05)'
                }}
                rightIcon={<FiArrowDownRight />}
                onClick={goToSection}
              >
                Grab Your RaaS Pass
              </Button>
            </HStack>
          </VStack>
        </GridItem>
        {isLargerThan800 && (
          <GridItem h='100%'>
            <VStack
              h='full'
              w='full'
              justify={'center'}
              align={'flex-start'}
              mt='15'
            >
              <Image src={nft_logo} w='full' />
            </VStack>
          </GridItem>
        )}
      </Grid>
      <Box h={isLargerThan800 ? '250px' : '50px'} w='full' />
      <Text
        fontWeight={'bold'}
        fontSize={isLargerThan800 ? '60px' : '40px'}
        lineHeight={isLargerThan800 ? '5rem' : '3rem'}
      >
        The HITMAKR RaaS Pass
      </Text>
      {isLargerThan800 && (
        <>
          {' '}
          <HStack
            spacing={'5'}
            borderRadius={'full'}
            color='black'
            flexWrap={'wrap'}
          >
            <Text
              fontSize='16px'
              fontWeight={'semibold'}
              border={'2px solid transparent'}
              bg='linear-gradient(#fff, #fff) padding-box,
                linear-gradient(to right, #3DA4A0, #9F3E90 ) border-box;'
              px='4'
              py='2'
              borderRadius={'full'}
            >
              3 Tier RaaS Pass
            </Text>
            <Text
              fontSize='16px'
              fontWeight={'semibold'}
              border={'2px solid transparent'}
              bg='linear-gradient(#fff, #fff) padding-box,
                linear-gradient(to right, #3DA4A0, #9F3E90 ) border-box;'
              px='4'
              py='2'
              borderRadius={'full'}
            >
              Lifetime Free Alpha Access
            </Text>
          </HStack>
          <Box h='30px' />
        </>
      )}
      {!isLargerThan800 && (
        <Text textAlign={'left'} w='full' py='3'>
          Click on an NFT to see more details
        </Text>
      )}
      <HStack
        id='nft_container'
        w='full'
        spacing={'5'}
        flexWrap={'wrap'}
        justify={'center'}
      >
        <NFT_Item
          name={'Silver'}
          abilities={[
            '90% Mint Chance',
            'Alpha Access',
            'Lifetime Free PRO Access',
            'Double AI Credits',
            'No Ads during audio ingestion',
            '& more...'
          ]}
        />
        <NFT_Item
          name={'Gold'}
          abilities={[
            '9% Mint Chance',
            'Alpha Access',
            'All Silver Benefits',
            'No Ads, ever'
          ]}
        />
        <NFT_Item
          name={'Pioneer'}
          abilities={[
            '1% Mint Chance',
            'All Gold Benefits',
            '40 In Existence',
            'Grandfather Key',
            'Free PILOT'
          ]}
        />
      </HStack>
      <Box h='50px' />
      <Button
        h='full'
        mt='-2'
        w='250px'
        // bg='#513184'
        color='white'
        px='4'
        py='5'
        fontSize={'22px'}
        border={'2px solid transparent'}
        bg='linear-gradient(#220E69, #220E69) padding-box,
                linear-gradient(to right, darkorchid, darkblue ) border-box;'
        boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
        transition={'all 300ms'}
        _hover={{
          bg: 'linear-gradient(#220E69, #220E6998) padding-box,linear-gradient(to right, darkorchid, darkblue ) border-box;',
          transform: 'scale(1.05)'
        }}
        onClick={handleModal}
      >
        <VStack>
          <Text>Grab Now</Text>
        </VStack>
      </Button>
      <HStack px='2' py='1' bg='white' color='black' mt='2' borderRadius={'md'}>
        <Image src={MetamaskIcon} h='20px' />
        <Text fontSize={'15px'}>Metamask required</Text>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'} p='0'>
        <ModalOverlay />
        <ModalContent
          borderRadius={'30px'}
          overflow={'hidden'}
          p='0'
          alignSelf={'center'}
          color='white'
          borderWidth={'thin'}
          borderColor={'#1b1233'}
          border={'2px solid transparent'}
          bg='linear-gradient(#1b1233, #1b1233) padding-box,
                linear-gradient(to right, darkorchid, darkblue ) border-box;'
          transition={'all 300ms'}
        >
          {/* <ModalHeader>Get your RaaS Pass Now!</ModalHeader> */}
          <ModalCloseButton mt='3' />
          <ModalBody p='0'>
            <VStack w='full' spacing={'0'}>
              <ConnectWallet />
              <VStack w='full' spacing={'0'} maxH='450px' overflow={'scroll'}>
                <NoBalance />
                <FreeRaasPass />
                <BuyRaasPass />
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

async function checkTokenDetails (walletAddress) {
  // Connect to an Ethereum node
  const web3 = new Web3(
    'https://eth-mainnet.g.alchemy.com/v2/cNYTuH5ofz0HEr5w7BINWOwic3-edVIk'
  )

  // Token contract address and ABI (Application Binary Interface)
  const tokenAddress = '0x3300b02efa180c99a2f61f4731665b51e4e254c4'

  const tokenABI = [
    // You need the ABI of the token contract
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'symbol',
      outputs: [{ name: '', type: 'string' }],
      type: 'function'
    }
  ]

  const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress)

  try {
    const balance = await tokenContract.methods.balanceOf(walletAddress).call()
    const balance_formatted = web3.utils.fromWei(balance, 'ether') * 10 ** 9
    console.log('Token Balance:', balance_formatted)

    const symbol = await tokenContract.methods.symbol().call()
    console.log('Token Symbol:', symbol)

    return { balance: balance_formatted, symbol }
  } catch (error) {
    console.error('Error:', error)
  }
}

export default App
