import {
  VStack,
  Button,
  Box,
  Text,
  HStack,
  Image,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  SliderMark,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/mainSlice'
import { useEffect, useState } from 'react'
import TokenTransferJson from '../../contracts/TokenTransfer.json'
import { FiArrowUpRight } from 'react-icons/fi'
import PioneerLogo from '../../assets/pioneer_logo.svg'
import SilverLogo from '../../assets/silver_logo.svg'
import GoldLogo from '../../assets/gold_logo.svg'
import { HiChevronDoubleRight } from 'react-icons/hi2'
import './customCss.css'

export default () => {
  const dispatch = useDispatch()
  const purchaseState = useSelector(state => state.main?.purchase)
  const walletState = useSelector(state => state.main?.wallet)
  const hmkrTokenState = useSelector(state => state.main?.hmkrToken)
  const usdcTokenState = useSelector(state => state.main?.usdcToken)
  const freeNFTState = useSelector(state => state.main?.freeNft)
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  useEffect(
    () => console.log('Purchase state:', purchaseState),
    [purchaseState]
  )

  const Buy_UI = () => {
    const [sliderValue, setSliderValue] = useState(50)

    const labelStyles = {
      mt: '2',
      ml: '-2.5',
      fontSize: 'sm',
      w: 'max-content'
    }

    const getSliderValue = () => {
      if (sliderValue >= 10 && sliderValue <= 30) {
        return {
          label: 'Only 1% Chance',
          bg: 'red',
          color: 'white',
          labelMl: '-15px',
          fontSize: '16px'
        }
      } else if (sliderValue > 30 && sliderValue < 50) {
        return {
          label: 'Slim Chance',
          bg: 'orange',
          color: 'black',
          fontSize: '19px'
        }
      } else if (sliderValue >= 50 && sliderValue < 80) {
        return {
          label: 'Good Chance',
          bg: 'yellow',
          color: 'black',
          fontSize: '21px'
        }
      } else if (sliderValue >= 80 && sliderValue <= 90) {
        return {
          label: 'Great Chance',
          bg: 'teal',
          color: 'white',
          fontSize: '21px',
          labelMl: '-85px'
        }
      } else if (sliderValue > 90 && sliderValue <= 100) {
        return {
          label: '10X Chance',
          bg: 'green',
          color: 'white',
          labelMl: '-105px',
          fontSize: '23px'
        }
      }
    }

    async function performPurchase () {
      // Check if MetaMask is installed
      dispatch(
        setAppState({
          key: 'purchase',
          value: {
            status: 'busy',
            statusText: 'Initializing...'
          }
        })
      )
      if (window.ethereum) {
        const contractAddresses = {
          tokenTransfer: process.env.REACT_APP_TRANSFER_TOKEN_CONTRACT_ADDRESS,
          usdc: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
          raasNFT: process.env.REACT_APP_RAAS_PASS_NFT_CONTRACT_ADDRESS
        }

        const step_one_setupProvider = () => {
          // Use your custom RPC provider for data querying
          return new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_SKALE_NETWORK_JSON_RPC_URL
          )
        }

        const step_two_getSigner = async () => {
          await window.ethereum.request({ method: 'eth_requestAccounts' })
          // Use the injected provider from MetaMask for transaction signing
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          const signer = provider.getSigner()

          return signer
        }

        const step_mid_distributeSFUEL = async walletAddress => {
          try {
            console.log('Distributing sFUEL')
            const sFuelResult = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}api/v1/users/distribute_sfuel`,
              {
                walletAddress: walletAddress
              }
            )
            console.log('sFUEL distribution successful!', sFuelResult)
            return {
              status: 'success'
            }
          } catch (ex) {
            console.log('Error distributing sfuel!', ex)
            return {
              status: 'error'
            }
          }
        }

        const step_three_allowUSDC = async ({
          provider,
          tokenTransferContractAddress,
          contractAddress,
          signer
        }) => {
          try {
            console.log('Setting USDC allowance...')
            // Get the contract instance
            const usdc_contractABI = [
              'function increaseAllowance(address spender, uint256 addedValue) public returns (bool)'
            ] // ABI of the increaseAllowance function
            const usdc_contract = new ethers.Contract(
              contractAddress,
              usdc_contractABI,
              provider
            )

            const usdc_contractWithSigner = usdc_contract.connect(signer)

            // Call the increaseAllowance function
            const tx = await usdc_contractWithSigner.increaseAllowance(
              tokenTransferContractAddress,
              500000000000
            )

            await tx.wait()
            console.log('Allowance result:', tx)
            return {
              status: 'success'
            }
          } catch (ex) {
            console.log('Error occurred when setting USDC allowance!', ex)
            return {
              status: 'error'
            }
          }
        }

        const step_four_performTransaction = async ({
          contractAddress,
          provider,
          signer
        }) => {
          try {
            const contractABI = TokenTransferJson.abi
            const contract = new ethers.Contract(
              contractAddress,
              contractABI,
              provider // Use custom provider for data querying
            )

            const contractWithSigner = contract.connect(signer)
            console.log('About to make the transaction now...')
            const transaction = await contractWithSigner.transferTokens(
              Math.round(sliderValue / 10) *
                process.env.REACT_APP_RAAS_PASS_BASE_PRICE
            )

            const receipt = await transaction.wait()
            console.log('Transaction to wallet receipt:', receipt)
            return {
              status: 'success',
              transactionHash: receipt.transactionHash
            }
          } catch (ex) {
            console.log('Final transaction failed!', ex)
            return {
              status: 'error'
            }
          }
        }

        const step_final = async ({ walletAddress, transactionHash }) => {
          try {
            console.log('Now about to reward NFT! Hold tight!')
            const postCall = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}api/v1/users/rewardNft`,
              {
                walletAddress,
                transactionHash,
                nftCount: sliderValue / 10
              }
            )
            return postCall.data.data
          } catch (ex) {
            console.log('Final transaction failed!', ex)
            return {
              status: 'error'
            }
          }
        }

        // Executing all steps!
        const provider = step_one_setupProvider()
        const signer = await step_two_getSigner()
        const walletAddress = walletState?.data?.account

        // Calling sFUEL distribution
        dispatch(
          setAppState({
            key: 'purchase',
            value: {
              statusText: 'Fueling up wallet...'
            }
          })
        )
        const step_mid_result = await step_mid_distributeSFUEL(walletAddress)

        if (step_mid_result.status === 'success') {
          // Calling step 3 and 4
          dispatch(
            setAppState({
              key: 'purchase',
              value: {
                statusText: 'Setting USDC allowance...'
              }
            })
          )
          const step_3_result = await step_three_allowUSDC({
            provider: provider,
            signer: signer,
            contractAddress: contractAddresses.usdc,
            tokenTransferContractAddress: contractAddresses.tokenTransfer
          })

          // Will call step 4 if step 3 was successful!
          if (step_3_result.status === 'success') {
            dispatch(
              setAppState({
                key: 'purchase',
                value: {
                  statusText: 'Executing Transaction...'
                }
              })
            )
            console.log(
              'Calling token transfer at:',
              contractAddresses.tokenTransfer
            )
            const step_4_result = await step_four_performTransaction({
              provider: provider,
              signer: signer,
              contractAddress: contractAddresses.tokenTransfer
            })

            if (step_4_result.status === 'success') {
              console.log(`${sliderValue} USDC Transaction was successful!`)
              dispatch(
                setAppState({
                  key: 'purchase',
                  value: {
                    statusText: 'Sending your RaaS Pass...'
                  }
                })
              )
              const _walletAddress = await signer.getAddress()
              const final_result = await step_final({
                walletAddress: _walletAddress,
                transactionHash: step_4_result.transactionHash
              })

              if (final_result.status === 'success') {
                console.log('Successfully rewarded NFT with ID:', final_result)
                dispatch(
                  setAppState({
                    key: 'purchase',
                    value: {
                      status: 'idle',
                      data: {
                        nftIDs: final_result.nftIDs,
                        amounts: final_result.amounts
                      }
                    }
                  })
                )
              } else {
                dispatch(
                  setAppState({
                    key: 'purchase',
                    value: {
                      status: 'idle',
                      error: {
                        type: 'nftreward',
                        message:
                          'Something went wrong when sending you the NFT!'
                      }
                    }
                  })
                )
              }
            } else {
              dispatch(
                setAppState({
                  key: 'purchase',
                  value: {
                    status: 'idle',
                    error: {
                      type: 'currency',
                      message:
                        'Something went wrong while trying to charge your wallet!'
                    }
                  }
                })
              )
              console.log('Token (ETH/USDC) transaction failed!')
            }
          } else {
            dispatch(
              setAppState({
                key: 'purchase',
                value: {
                  status: 'idle',
                  error: {
                    type: 'currency.allowance',
                    message:
                      'Something went wrong while setting allowance from your wallet!'
                  }
                }
              })
            )
            console.log('USDC allowance failed!')
          }
        } else {
          dispatch(
            setAppState({
              key: 'purchase',
              value: {
                status: 'idle',
                error: {
                  type: 'sFuel',
                  message:
                    'Something went wrong while sending sFuel to your wallet!'
                }
              }
            })
          )
          console.log('sFUEL distribution failed!')
        }
      } else {
        dispatch(
          setAppState({
            key: 'purchase',
            value: {
              status: 'idle',
              error: {
                type: 'metamask',
                message: 'Could not find MetaMask! Please install MetaMask!'
              }
            }
          })
        )
        console.warn('Could not find MetaMask! Please install MetaMask!')
      }
    }

    const shouldRnder = () => {
      if (!purchaseState?.data || !purchaseState?.data?.nftIDs) {
        if (usdcTokenState?.status !== 'busy') {
          if (hmkrTokenState?.data?.balance <= 0) {
            return (
              usdcTokenState?.data?.balance >=
                process.env.REACT_APP_RAAS_PASS_BASE_PRICE &&
              purchaseState?.data?.nftID == undefined
            )
          } else {
            return freeNFTState?.data?.available == false
          }
        }
      }
    }

    return (
      shouldRnder() && (
        <VStack w='full' align={'flex-start'} pt='5' borderRadius={'md'}>
          <VStack w='full' align={'flex-start'} px='6' pb='4'>
            <Text fontSize={'22px'} fontWeight={'bold'}>
              You can get the RaaS Pass!
            </Text>
            <Text>
              {freeNFTState?.status === 'idle' &&
              freeNFTState?.data?.available == false ? (
                ''
              ) : (
                <>
                  You are currently not one of the holders of the HITMAKR($HMKR)
                  token. We are airdropping 1,000 Free RaaS Pass to HMKR token
                  holders! <br />
                </>
              )}
              You can mint the most exclusive <b>PIONEER RaaS Pass</b> by
              setting your chances yourself!
            </Text>
            <Box h='1' />
            {purchaseState?.status !== 'busy' && (
              <Box
                px={4}
                pt={'50px'}
                pb='4'
                w='full'
                borderRadius={'10px'}
                bg='#ffffff20'
                pos='relative'
              >
                <Text
                  fontSize={isLargerThan800 ? '90px' : '70px'}
                  fontWeight={'bold'}
                  p='0'
                  w='full'
                  textAlign={'center'}
                  pos={'absolute'}
                  top='0'
                  lineHeight={'6rem'}
                  left='0'
                  transition={'all 300ms'}
                  opacity={
                    sliderValue / 100 / 10 <= 0.03
                      ? '0.03'
                      : sliderValue / 100 / 6
                  }
                >
                  PIONEER
                </Text>
                <Slider
                  aria-label='slider-ex-6'
                  onChange={val => setSliderValue(val)}
                  defaultValue={50}
                  min={10}
                  max={100}
                  step={10}
                  transition={'all 300ms'}
                >
                  <SliderMark value={10} {...labelStyles}>
                    {/* 1 Time */}
                  </SliderMark>
                  <SliderMark value={30} {...labelStyles}>
                    {/* 3 Times */}
                  </SliderMark>
                  <SliderMark value={70} {...labelStyles}>
                    {/* 7 Times */}
                  </SliderMark>
                  <SliderMark value={100} {...labelStyles}>
                    {/* 10 Times */}
                  </SliderMark>
                  <SliderMark
                    value={sliderValue}
                    textAlign='center'
                    mt='-45px'
                    ml={getSliderValue().labelMl || '-30px'}
                    w='max-content'
                    fontWeight={'semibold'}
                    transition={'all 300ms'}
                  >
                    <HStack h='max-content' w='max-content' spacing={'2'}>
                      <Text
                        borderRadius={'md'}
                        px='4'
                        py='0.5'
                        bg={getSliderValue().bg}
                        color={getSliderValue().color}
                      >
                        {getSliderValue().label}
                      </Text>
                      <HStack
                        transition={'all 300ms'}
                        color={'#ffffff'}
                        animation={'moveBackAndForth 2s infinite'}
                        opacity={sliderValue < 80 ? '1' : 0}
                      >
                        <Text>Slide</Text>
                        <HiChevronDoubleRight />
                      </HStack>
                    </HStack>
                  </SliderMark>
                  <SliderTrack transition={'all 300ms'} h='5px'>
                    <SliderFilledTrack
                      bg={getSliderValue().bg}
                      transition={'all 300ms'}
                    />
                  </SliderTrack>
                  <SliderThumb w='20px' h='20px' transition={'all 300ms'} />
                </Slider>
              </Box>
            )}
          </VStack>

          <Tooltip
            label={
              usdcTokenState?.data?.balance < sliderValue &&
              'Not enough balance. Try refreshing your balance.'
            }
            placement='top'
            bg='white'
            color='black'
          >
            <Button
              isDisabled={usdcTokenState?.data?.balance < sliderValue}
              loadingText={purchaseState?.statusText}
              isLoading={purchaseState?.status === 'busy'}
              size={'lg'}
              mt='-2'
              w='full'
              // bg='#513184'
              px='4'
              py='8'
              bg='#07d600'
              borderTopRadius={'0px'}
              fontSize={isLargerThan800 ? getSliderValue().fontSize : '20px'}
              color='black'
              fontWeight={'bold'}
              //             border={'2px solid transparent'}
              //             bg='linear-gradient(#220E69, #220E69) padding-box,
              // linear-gradient(to right, darkorchid, darkblue ) border-box;'
              boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
              transition={
                'all 300ms, font-size 0.5s cubic-bezier(0.65, -0.25, 0.35, 1.5)'
              }
              _hover={{
                bg: 'white',
                transform: 'scale(1.05)'
              }}
              onClick={performPurchase}
            >
              Mint {sliderValue / 10} RaaS Pass for{' '}
              {Math.round(sliderValue / 10) *
                process.env.REACT_APP_RAAS_PASS_BASE_PRICE}{' '}
              USDC Now!
            </Button>
          </Tooltip>
        </VStack>
      )
    )
  }

  const Success_UI = () => {
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
    const purchased_nft_ids = purchaseState?.data?.nftIDs

    return (
      purchased_nft_ids !== undefined && (
        <VStack w='full' align={'flex-start'}>
          <VStack
            w='full'
            h='full'
            align={'flex-start'}
            justify={'space-between'}
            px='6'
            py='4'
          >
            <Text fontSize={'22px'} fontWeight={'bold'}>
              Congratulations!
            </Text>
            <Text>
              You have been successfully minted{' '}
              <b>
                {purchaseState?.data?.amounts.reduce((a, b) => a + b, 0)} RaaS
                Passes!
              </b>
            </Text>
            <HStack flexWrap={'wrap'} w='full' justify={'stretch'} py='3'>
              {purchased_nft_ids.map((id, nftIndex) => (
                <VStack
                  w={isLargerThan800 ? 'auto' : 'full'}
                  key={`raas_pass_${id}`}
                  bg='#00000030'
                  // px='6'
                  pt='4'
                  borderRadius={'10px '}
                  border='1px solid #ffffff20'
                  pos='relative'
                  overflow={'hidden'}
                >
                  <Text
                    fontSize={'40px'}
                    fontWeight={'bold'}
                    color={'#07d60060'}
                    // textShadow={'green 0px 0px 6px'}
                    // style={{
                    //   '-webkit-font-smoothing': 'antialiased'
                    // }}
                    pos='absolute'
                    top='0px'
                    left='5px'
                  >
                    {purchaseState?.data?.amounts[nftIndex]}x
                  </Text>
                  <Image
                    src={
                      id == 0
                        ? PioneerLogo
                        : id == 1
                        ? GoldLogo
                        : id == 2
                        ? SilverLogo
                        : SilverLogo
                    }
                    h='100px'
                    w={'full'}
                  />
                  <Text
                    w='full'
                    fontWeight={'bold'}
                    px='6'
                    py='3'
                    borderTopRadius={'10px'}
                    bg='white'
                    color='black'
                  >
                    {id == 0
                      ? 'PIONEER'
                      : id == 1
                      ? 'GOLD'
                      : id == 2
                      ? 'SILVER'
                      : ''}{' '}
                    Pass
                  </Text>
                </VStack>
              ))}
            </HStack>
          </VStack>

          <Button
            size={'lg'}
            w='full'
            // bg='#513184'
            mt='-2'
            // bg='#513184'
            px='4'
            py='8'
            bg='#07d600'
            borderTopRadius={'0px'}
            fontSize={'22px'}
            color='black'
            fontWeight={'bold'}
            //             border={'2px solid transparent'}
            //             bg='linear-gradient(#220E69, #220E69) padding-box,
            // linear-gradient(to right, darkorchid, darkblue ) border-box;'
            boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
            transition={
              'all 300ms, font-size 0.5s cubic-bezier(0.65, -0.25, 0.35, 1.5)'
            }
            _hover={{
              bg: 'white',
              transform: 'scale(1.05)'
            }}
            rightIcon={<FiArrowUpRight />}
            onClick={() => window.open('https://player.hitmakr.io', '_blank')}
          >
            Access Alpha Now
          </Button>
        </VStack>
      )
    )
  }

  return (
    <>
      <Buy_UI />
      <Success_UI />
    </>
  )
}
