import { useState } from 'react'
import {
  VStack,
  Button,
  Box,
  Text,
  HStack,
  Image,
  useMediaQuery,
  Input,
  Spinner
} from '@chakra-ui/react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/mainSlice'
import { useEffect } from 'react'

export default () => {
  const dispatch = useDispatch()
  const [adminSendStatus, setAdminSendStatus] = useState()
  const hmkrTokenState = useSelector(state => state.main?.hmkrToken)
  const walletState = useSelector(state => state.main?.wallet)
  const freeNFTState = useSelector(state => state.main?.freeNft)
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  useEffect(() => {
    checkFreeNFTAvailability()
  }, [])

  function performSharePost () {
    window.open('https://twitter.com/compose/post', '_blank')
  }

  async function sendToAdmin (twitterLink) {
    if (twitterLink) {
      setAdminSendStatus('pending')
      try {
        const api = `${process.env.REACT_APP_BACKEND_URL}api/v1/users/addPreviousHolder`
        await axios.post(api, {
          wallet: walletState?.data?.account,
          twitterTestimonial: twitterLink
        })
        setAdminSendStatus('success')
      } catch (ex) {
        setAdminSendStatus('failed')
        setTimeout(() => {
          setAdminSendStatus('idle')
        }, 3500)
      }
    }
  }

  async function checkFreeNFTAvailability () {
    console.log('Checking free nft availability')
    dispatch(
      setAppState({
        key: 'freeNft',
        value: {
          status: 'busy'
        }
      })
    )
    try {
      const api = `${process.env.REACT_APP_BACKEND_URL}api/v1/users/checkFreeNFTAvailability`
      const result = await axios.get(api)
      if (result.data?.data?.remainingQuota > 0) {
        dispatch(
          setAppState({
            key: 'freeNft',
            value: {
              data: { available: true },
              status: 'idle'
            }
          })
        )
      } else {
        dispatch(
          setAppState({
            key: 'freeNft',
            value: {
              data: { available: false },
              status: 'idle'
            }
          })
        )
      }
    } catch (ex) {
      console.log('API Call failed! Err:', ex)
      dispatch(
        setAppState({
          key: 'freeNft',
          value: {
            data: null,
            status: 'idle'
          }
        })
      )
    }
  }

  const Init_UI = () => {
    return (
      freeNFTState?.status === 'busy' && (
        <HStack w='full' align={'flex-start'} px='4' py='4'>
          <Spinner />
          <Text>Checking if there are Free RaaS Pass left for airdropping</Text>
        </HStack>
      )
    )
  }

  const NoNFTLeft = () => {
    return (
      freeNFTState?.data?.available == false && (
        <HStack w='full' align={'flex-start'} px='6' py='4' pb='0'>
          <Text>
            We have already airdropped 1000 RaaS Passes! But you can still mint
            our most exclusive <b>Pioneer RaaS Pass!</b>
          </Text>
        </HStack>
      )
    )
  }

  const FreeNFT_UI = () => {
    const [twitterLink, setTwitterLink] = useState('')
    return (
      adminSendStatus !== 'success' &&
      freeNFTState?.data?.available && (
        <VStack w='full' align={'flex-start'} pt='5' borderRadius={'md'}>
          <VStack w='full' align={'flex-start'} px='6' pb='4'>
            <Text fontSize={'22px'} fontWeight={'bold'}>
              Congratulations!
            </Text>
            <Text>
              You are one of the holders of the HMKR token and you are eligible
              for <b>1 Free RaaS Paas!</b>
              <br />
              <Text fontSize={'15px'}>
                Share a few words about us on <b>X(formerly Twitter)</b> to
                claim your free RaaS Pass.{'    '}
                <Button
                  size={'sm'}
                  onClick={performSharePost}
                  bg='transparent'
                  px='0'
                  py='0'
                  borderRadius={'0'}
                  borderBottom={'1px dashed #858585'}
                  color='gray.400'
                  _hover={{
                    bg: 'transparent',
                    color: 'white',
                    borderBottom: '1px solid #d4d4d4'
                  }}
                  transition={'all 300ms'}
                >
                  Click here to share
                </Button>
              </Text>
            </Text>
            <Input
              mt='2'
              size='md'
              border={'none'}
              outline={'none'}
              bg='#ffffff20'
              placeholder='Paste the post/thread link here and click "Claim"'
              _placeholder={{
                color: 'gray.300'
              }}
              value={twitterLink}
              onChange={e => setTwitterLink(e.target.value)}
            />
          </VStack>
          <Button
            isDisabled={!twitterLink}
            loadingText='Please wait...'
            isLoading={adminSendStatus === 'pending'}
            size={'lg'}
            mt='-2'
            w='full'
            // bg='#513184'
            px='4'
            py='8'
            bg='#07d600'
            borderTopRadius={'0px'}
            fontSize={'22px'}
            color='black'
            fontWeight={'bold'}
            //             border={'2px solid transparent'}
            //             bg='linear-gradient(#220E69, #220E69) padding-box,
            // linear-gradient(to right, darkorchid, darkblue ) border-box;'
            boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
            transition={
              'all 300ms, font-size 0.5s cubic-bezier(0.65, -0.25, 0.35, 1.5)'
            }
            _hover={{
              bg: 'white',
              transform: 'scale(1.05)'
            }}
            onClick={() => sendToAdmin(twitterLink)}
          >
            Claim Your Free RaaS Pass
          </Button>
          {adminSendStatus === 'failed' && (
            <Text px='3' py='2'>
              You have already requested a free RaaS Pass from this wallet!
            </Text>
          )}
        </VStack>
      )
    )
  }

  const Success_UI = () => {
    return (
      adminSendStatus === 'success' && (
        <VStack
          w='full'
          align={'flex-start'}
          px='4'
          py='4'
          border={'2px solid transparent'}
          bg='linear-gradient(#33195C, #592C83) padding-box,
          linear-gradient(to right, darkorchid, #556BAA ) border-box;'
          boxShadow={'2px 18px 31px -5px rgba(39,31,111,0.75)'}
          borderRadius={'md'}
        >
          <Text fontSize={'22px'} fontWeight={'bold'}>
            Your Free RaaS Pass is on the way!
          </Text>
          <Text>
            We will verify your submission shortly and reward your wallet with
            the NFT! Please note that this is a manual process and it might take
            a while for you to receive your RaaS Pass.
          </Text>
          <Text>
            Once you've received your RaaS Pass, you can log into{' '}
            <u>https://player.hitmakr.io</u> and access our alpha!
          </Text>
        </VStack>
      )
    )
  }

  return (
    hmkrTokenState?.data?.balance > 0 && (
      <>
        <Init_UI />
        <NoNFTLeft />
        <FreeNFT_UI />
        <Success_UI />
      </>
    )
  )
}
