import { createSlice } from '@reduxjs/toolkit'
import { produce } from 'immer'

// Utility function to deep merge two objects
const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]))
    }
  }
  return { ...target, ...source }
}

// All object in the state should look like: {data, status, statusText, error: {type, message, errorString}}

const initialState = {}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setAppState: (state, action) => {
      const { key, value } = action.payload
      const keys = key.split('.')

      return produce(state, draft => {
        let current = draft
        for (let i = 0; i < keys.length - 1; i++) {
          const part = keys[i]
          if (!current[part]) {
            current[part] = {}
          }
          current = current[part]
        }
        const lastKey = keys[keys.length - 1]
        if (typeof current[lastKey] === 'object' && typeof value === 'object') {
          current[lastKey] = deepMerge(current[lastKey], value)
        } else {
          current[lastKey] = value
        }
      })
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAppState } = mainSlice.actions

export default mainSlice.reducer
