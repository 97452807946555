import {
  VStack,
  Button,
  Box,
  Text,
  HStack,
  Image,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  SliderMark,
  useMediaQuery,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/mainSlice'
import { useEffect, useState } from 'react'
import TokenTransferJson from '../../contracts/TokenTransfer.json'
import { FiArrowUpRight, FiExternalLink } from 'react-icons/fi'
import PioneerLogo from '../../assets/pioneer_logo.svg'
import SilverLogo from '../../assets/silver_logo.svg'
import GoldLogo from '../../assets/gold_logo.svg'
import { HiChevronDoubleRight } from 'react-icons/hi2'
import './customCss.css'

export default () => {
  const walletState = useSelector(state => state.main?.wallet)
  const hmkrTokenState = useSelector(state => state.main?.hmkrToken)
  const usdcTokenState = useSelector(state => state.main?.usdcToken)
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  return (
    usdcTokenState?.data?.balance <
      process.env.REACT_APP_RAAS_PASS_BASE_PRICE &&
    hmkrTokenState?.data?.balance <= 0 && (
      <VStack w='full' align={'flex-start'} pt='5' borderRadius={'md'}>
        <VStack w='full' align={'flex-start'} px='6' pb='4'>
          <Text fontSize={'22px'} fontWeight={'bold'}>
            You don't have enough balance!
          </Text>
          <Text>
            The <b>Skale Calypso account</b> that you connected, doesn't have
            the minimum balance{' '}
            <b>({process.env.REACT_APP_RAAS_PASS_BASE_PRICE} USDC)</b> required
            for minting RaaS Pass.
          </Text>
          <Text>
            To solve this, you have <b>a few options:</b>
          </Text>
          <Box h='1' />
          <Accordion allowToggle w='full'>
            <AccordionItem border={'0'} bg='#ffffff20' borderRadius={'md'}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    <VStack
                      as='span'
                      flex='1'
                      textAlign='left'
                      fontWeight={'semibold'}
                      align={'flex-start'}
                    >
                      <Text>
                        Option 1:{' '}
                        {!isExpanded
                          ? 'Purchase USDC for your SKALE Wallet'
                          : 'Buy USDC for SKALE wallet and bridge it'}
                      </Text>
                    </VStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack spacing={'2'}>
                      <Text>
                        <b>Step 1:</b> Purchase USDC for your SKALE Europa
                        Wallet via Transak. Transak supports various payment
                        methods including card payments, Google Pay, Apple Pay
                        etc.
                      </Text>
                      <Button
                        size={'sm'}
                        w='full'
                        onClick={() =>
                          window.open('https://global.transak.com/', '_blank')
                        }
                        _hover={{
                          bg: 'black',
                          color: 'white'
                        }}
                        rightIcon={<FiExternalLink />}
                      >
                        Buy $USDC on Transak
                      </Button>
                      <Text fontWeight={'bold'}>
                        Please make sure to select "SKALE" as the Network and
                        "USDC" as the cryptocurrency on the Transak window
                      </Text>
                      <Text pt='2'>
                        <b>Step 2:</b> Bridge your SKALE Europa USDC to your
                        SKALE Calypso wallet from the below link:
                      </Text>
                      <Button
                        size={'sm'}
                        w='full'
                        onClick={() =>
                          window.open(
                            'https://portal.skale.space/bridge?from=elated-tan-skat&to=honorable-steel-rasalhague&token=usdc&type=erc20',
                            '_blank'
                          )
                        }
                        _hover={{
                          bg: 'black',
                          color: 'white'
                        }}
                        rightIcon={<FiExternalLink />}
                      >
                        Bridge Europa USDC to Calypso
                      </Button>
                      <Text pt='2'>
                        Once done, simply click the <b>"Reload Balance"</b> button.
                      </Text>
                    </VStack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              border={'0'}
              bg='#ffffff20'
              borderRadius={'md'}
              mt='2'
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    <VStack
                      as='span'
                      flex='1'
                      textAlign='left'
                      fontWeight={'semibold'}
                      align={'flex-start'}
                    >
                      <Text>
                        Option 2:{' '}
                        {!isExpanded
                          ? 'For wallet holders who have USDC'
                          : 'Swap USDC from any network, for Calypso USDC'}
                      </Text>
                    </VStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack spacing={'2'}>
                      <Text>
                        If you have USDC on your Ethereum wallet, you can swap
                        that for SKALE Calypso USDC, via Meson.
                      </Text>
                      <Button
                        size={'sm'}
                        w='full'
                        onClick={() =>
                          window.open('https://meson.fi/', '_blank')
                        }
                        _hover={{
                          bg: 'black',
                          color: 'white'
                        }}
                        rightIcon={<FiExternalLink />}
                      >
                        Swap $USDC on Meson
                      </Button>
                    </VStack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              border={'0'}
              bg='#ffffff20'
              borderRadius={'md'}
              mt='2'
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    <VStack
                      as='span'
                      flex='1'
                      textAlign='left'
                      fontWeight={'semibold'}
                      align={'flex-start'}
                    >
                      <Text>
                        Option 3:{' '}
                        {!isExpanded
                          ? 'For wallet holders who have ETH'
                          : 'ETH to USDC, then to Calypso USDC'}
                      </Text>
                    </VStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack spacing={'2'} align={'flex-start'}>
                      <Text>
                        <b>Step 1:</b> Swap your ETH with USDC via MetaMask Swap
                        or Uniswap.
                      </Text>
                      <HStack
                        w='full'
                        justify={'space-between'}
                        flexWrap={isLargerThan800 ? 'nowrap' : 'wrap'}
                      >
                        <Button
                          w='50%'
                          size={'sm'}
                          onClick={() =>
                            window.open(
                              'https://portfolio.metamask.io/swap',
                              '_blank'
                            )
                          }
                          _hover={{
                            bg: 'black',
                            color: 'white'
                          }}
                        >
                          Swap on MetaMask
                        </Button>
                        <Button
                          w='50%'
                          size={'sm'}
                          onClick={() =>
                            window.open('https://app.uniswap.org/', '_blank')
                          }
                          _hover={{
                            bg: 'black',
                            color: 'white'
                          }}
                        >
                          Swap on UniSwap
                        </Button>
                      </HStack>
                      <Box />
                      <Text>
                        <b>Step 2:</b> Swap your USDC with SKALE Calypso USDC
                        via Meson.
                      </Text>
                      <Button
                        size={'sm'}
                        w='full'
                        onClick={() =>
                          window.open('https://meson.fi/', '_blank')
                        }
                        _hover={{
                          bg: 'black',
                          color: 'white'
                        }}
                        rightIcon={<FiExternalLink />}
                      >
                        Swap $USDC on Meson
                      </Button>
                      <Text fontWeight={'bold'}>
                        Note that the price of 1 RaaS Pass in <b>0.025 ETH</b>{' '}
                        or{' '}
                        <b>{process.env.REACT_APP_RAAS_PASS_BASE_PRICE} USDC</b>
                        . You can SWAP a larger amount of ETH to buy multiple
                        RaaS Pass to increase your chances of minting the most
                        exclusive <b>PIONEER RaaS Pass</b>
                      </Text>
                    </VStack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
          <Box h='1' />
        </VStack>
      </VStack>
    )
  )
}
