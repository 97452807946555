import {
  VStack,
  Button,
  Text,
  HStack,
  Box,
  Spinner,
  Tooltip
} from '@chakra-ui/react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/mainSlice'
import { useEffect, useState } from 'react'
import { FiCheck, FiRefreshCw } from 'react-icons/fi'
import { IoReloadCircle } from 'react-icons/io5'
import { RxReload } from 'react-icons/rx'

export default ({ stepNo }) => {
  const dispatch = useDispatch()
  const walletState = useSelector(state => state.main?.wallet)
  const hmkrTokenState = useSelector(state => state.main?.hmkrToken)
  const usdcTokenState = useSelector(state => state.main?.usdcToken)

  async function checkHmkrToken (walletAddress) {
    dispatch(
      setAppState({
        key: 'hmkrToken',
        value: {
          status: 'busy',
          statusText: 'Checking Free RaaS Pass eligibility'
        }
      })
    )
    // Connect to an Ethereum node
    const web3 = new Web3(
      'https://eth-mainnet.g.alchemy.com/v2/cNYTuH5ofz0HEr5w7BINWOwic3-edVIk'
    )

    // Token contract address and ABI (Application Binary Interface)
    const tokenAddress = '0x3300b02efa180c99a2f61f4731665b51e4e254c4'

    const tokenABI = [
      // You need the ABI of the token contract
      {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ name: '', type: 'string' }],
        type: 'function'
      }
    ]

    const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress)

    try {
      const balance = await tokenContract.methods
        .balanceOf(walletAddress)
        .call()
      const balance_formatted = web3.utils.fromWei(balance, 'ether') * 10 ** 9
      console.log('Token Balance:', balance_formatted)

      const symbol = await tokenContract.methods.symbol().call()
      console.log('Token Symbol:', symbol)

      dispatch(
        setAppState({
          key: 'hmkrToken.data',
          value: {
            balance: balance_formatted,
            symbol: 'HMKR'
          }
        })
      )
    } catch (error) {
      dispatch(
        setAppState({
          key: 'hmkrToken',
          value: {
            data: null,
            error: {
              type: 'contract',
              message: 'Could not check if wallet has $HMKR token.',
              errorString: `Main Error: ${error}`
            }
          }
        })
      )
      console.error('Error:', error)
    }

    dispatch(
      setAppState({
        key: 'hmkrToken',
        value: {
          status: 'idle',
          statusText: null
        }
      })
    )
  }

  async function checkCalypsoUSDCToken (wAddress) {
    dispatch(
      setAppState({
        key: 'usdcToken',
        value: {
          status: 'busy',
          statusText: 'Checking wallet for balance'
        }
      })
    )
    const walletAddress = wAddress || walletState?.data?.account
    // Connect to an Ethereum node
    const web3 = new Web3(process.env.REACT_APP_SKALE_NETWORK_JSON_RPC_URL)

    // Token contract address and ABI (Application Binary Interface)
    const tokenAddress = process.env.REACT_APP_USDC_CONTRACT_ADDRESS

    const tokenABI = [
      // You need the ABI of the token contract
      {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function'
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ name: '', type: 'string' }],
        type: 'function'
      }
    ]

    const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress)

    try {
      const balance = await tokenContract.methods
        .balanceOf(walletAddress)
        .call()
      const balance_formatted = web3.utils.fromWei(balance, 'wei') / 10 ** 6
      console.log('Token Balance:', balance_formatted)

      const symbol = await tokenContract.methods.symbol().call()
      console.log('Token Symbol:', symbol)

      dispatch(
        setAppState({
          key: 'usdcToken.data',
          value: {
            balance: balance_formatted,
            symbol: 'USDC'
          }
        })
      )
    } catch (error) {
      dispatch(
        setAppState({
          key: 'usdcToken',
          value: {
            data: null,
            error: {
              type: 'contract',
              message: 'Could not check if wallet has Calypso $USDC token.',
              errorString: `Main Error: ${error}`
            }
          }
        })
      )
      console.error('Error:', error)
    }
    dispatch(
      setAppState({
        key: 'usdcToken',
        value: {
          status: 'idle',
          statusText: null
        }
      })
    )
  }

  async function switchNetwork () {
    if (typeof window.ethereum !== 'undefined') {
      //   dispatch(
      //     setAppState({
      //       key: 'wallet.error',
      //       value: null
      //     })
      //   )
      const web3 = new Web3(window.ethereum)
      // Check the current network
      const currentChainId = await web3.eth.getChainId()
      const desiredChainId = process.env.REACT_APP_SKALE_CHAIN_ID // Example: 0x1 is the chain ID for Ethereum Mainnet
      if (currentChainId !== desiredChainId) {
        try {
          // Request to switch to the desired network
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: desiredChainId }]
          })
          console.log('Network switched successfully.')
          return {
            status: 'success'
          }
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask
          console.log('Error code:', switchError.code)
          if (
            switchError.code == 4902 ||
            switchError.code == -32603 ||
            switchError.code == -3200
          ) {
            try {
              console.log('Trying to add new network...')
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: desiredChainId,
                    chainName: process.env.REACT_APP_SKALE_NETWORK_NAME,
                    nativeCurrency: {
                      name: 'ETH',
                      symbol: 'ETH',
                      decimals: 18
                    },
                    rpcUrls: [process.env.REACT_APP_SKALE_NETWORK_JSON_RPC_URL], // Replace with your RPC URL
                    blockExplorerUrls: [
                      process.env.REACT_APP_SKALE_BLOCK_EXPLORER_URL
                    ]
                  }
                ]
              })
              // After adding, try switching again
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: desiredChainId }]
              })
              console.log('Network added and switched successfully.')
              return {
                status: 'success'
              }
            } catch (addError) {
              console.error('Failed to add the network', addError)
              dispatch(
                setAppState({
                  key: 'wallet.error',
                  value: {
                    type: 'metamask.network',
                    message: `Could not add the network.`,
                    errorString: `Main error: ${addError}`
                  }
                })
              )
              return {
                status: 'error'
              }
            }
          } else {
            console.error('Failed to switch the network', switchError)
            dispatch(
              setAppState({
                key: 'wallet.error',
                value: {
                  type: 'metamask.network',
                  message: `Could not switch the network.`,
                  errorString: `Main error: ${switchError}`
                }
              })
            )
            return {
              status: 'error'
            }
          }
        }
      } else {
        console.log('Already on the desired network.')
        return {
          status: 'success'
        }
      }
    } else {
      console.error('MetaMask is not installed.')
      dispatch(
        setAppState({
          key: 'wallet.error',
          value: {
            type: 'metamask.unavailable',
            message: 'Metamask is not installed.'
          }
        })
      )
      return {
        status: 'error'
      }
    }
  }

  const connectWallet = async () => {
    dispatch(
      setAppState({
        key: 'wallet',
        value: {
          status: 'busy',
          statusText: 'Connecting wallet...'
        }
      })
    )
    try {
      // Request account access if needed
      const _switch = await switchNetwork()
      console.log('Switch res:', _switch)
      if (_switch.status === 'success') {
        await window.ethereum.request({ method: 'eth_requestAccounts' })

        const web3 = new Web3(window.ethereum)
        const accounts = await web3.eth.getAccounts()
        // setAccount(accounts[0])
        dispatch(
          setAppState({ key: 'wallet.data.account', value: accounts[0] })
        )
        dispatch(
          setAppState({
            key: 'wallet',
            value: {
              status: 'idle',
              statusText: null
            }
          })
        )
        await checkHmkrToken(accounts[0])

        await checkCalypsoUSDCToken(accounts[0])

        // setToken(getToken)
      } else {
        console.log('Could not switch network!')
      }
    } catch (error) {
      console.error('User rejected the request', error)
    }
    dispatch(
      setAppState({
        key: 'wallet',
        value: {
          status: 'idle',
          statusText: null
        }
      })
    )
  }

  const Connect_Wallet = () => {
    return (
      <VStack
        w='full'
        align={'flex-start'}
        px='6'
        py='6'
        bg='#ffffff20'
        //   borderTopRadius={'30px'}
      >
        <Text fontSize={'18px'} fontWeight={'semibold'}>
          Let's Start By Connecting Your Wallet
        </Text>
        <HStack>
          <Button
            size={'md'}
            onClick={connectWallet}
            _hover={{
              bg: 'black',
              color: 'white'
            }}
            isLoading={
              walletState?.status === 'busy' ||
              hmkrTokenState?.status === 'busy' ||
              usdcTokenState?.status === 'busy'
            }
            loadingText={
              walletState?.statusText ||
              hmkrTokenState?.statusText ||
              usdcTokenState?.statusText
            }
          >
            {walletState?.data?.account
              ? `Connected: ${walletState?.data?.account?.substring(
                  0,
                  5
                )}...${walletState?.data?.account?.substring(
                  walletState?.data?.account?.length - 3
                )}`
              : 'Connect Wallet'}
          </Button>
          {walletState?.data?.account && usdcTokenState?.status === 'idle' && (
            <Tooltip label='Reload balance'>
              <Button
                size={'md'}
                onClick={() => checkCalypsoUSDCToken()}
                bg='#4d3e75'
                color='white'
                _hover={{
                  bg: 'black',
                  color: 'white'
                }}
                leftIcon={<RxReload />}
              >
                {usdcTokenState?.data?.balance || '0'} USDC
              </Button>
            </Tooltip>
          )}
        </HStack>
        {/* {metamaskErr && (
        <>
          <Button
            size={'sm'}
            onClick={() => window.open('https://metamask.io/', '_blank')}
            bg='transparent'
            px='0'
            py='0'
            borderRadius={'0'}
            borderBottom={'1px dashed #858585'}
            color='gray.400'
            _hover={{
              bg: 'transparent',
              color: 'white',
              borderBottom: '1px solid #d4d4d4'
            }}
            transition={'all 300ms'}
          >
            Click here to install MetaMask extension
          </Button>
          <Text>Please refresh this page after installing Metamask</Text>
        </>
      )} */}
      </VStack>
    )
  }

  return (
    <>
      <Connect_Wallet />
    </>
  )
}
